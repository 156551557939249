<template>
  <component
    v-bind:is="upsellCardComponent"
    v-bind="props"
    @apply="apply"
  />
</template>

<script>
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { LOAN_DASHBOARD } from '@/TrackingActions'
import UpsellCardLt from './UpsellCardLt'
import UpsellCardEe from './UpsellCardEe'
import UpsellCardComponent from './UpsellCardComponent'
import { mapState } from 'pinia'
import { useRootStore } from '../../../../store/root'

const viewActions = {
  1: LOAN_DASHBOARD.VIEW_LEVEL_ONE_OFFER_CARD,
  2: LOAN_DASHBOARD.VIEW_LEVEL_TWO_OFFER_CARD,
  3: LOAN_DASHBOARD.VIEW_LEVEL_THREE_OFFER_CARD
}

const applyActions = {
  1: LOAN_DASHBOARD.APPLY_FOR_LEVEL_ONE_OFFER,
  2: LOAN_DASHBOARD.APPLY_FOR_LEVEL_TWO_OFFER,
  3: LOAN_DASHBOARD.APPLY_FOR_LEVEL_THREE_OFFER
}

const componentMap = {
  EE: 'upsell-card-ee',
  LT: 'upsell-card-lt',
  DEFAULT: 'upsell-card-component'
}

export default {
  name: 'offer-card',
  components: {
    UpsellCardLt,
    UpsellCardEe,
    UpsellCardComponent
  },
  props: {
    currency: {
      type: String,
      required: true
    },
    offer: {
      type: Object,
      required: true
    },
    index: {
      required: true
    },
    count: {
      required: true
    },
    spacerClass: {
      required: false
    }
  },
  data () {
    return {
      translations: {
        title: {
          1: this.$pgettext('offer_card', 'You have an unused loan of'),
          2: this.$pgettext('offer_card', 'You now have the opportunity to apply for additional loan at significantly lower rates'),
          3: this.$pgettext('offer_card_title_segment_3', 'Thanks for being our good Customer! You now have the opportunity to apply for a new loan at significantly lower rates')
        },
        lead: {
          1: this.$pgettext('offer_card', 'We will pay the loan to your account as soon as you sign the contract'),
          2: this.$pgettext('offer_card', 'Check your pre-filled application and ask for a loan offer'),
          3: this.$pgettext('offer_card_lead_segment_3', 'Check your pre-filled application and ask for a loan offer')
        },
        openingFee: {
          2: {
            label: this.$gettext('Opening fee'),
            value: this.$pgettext('offer_card', 'OPENING_FEE')
          },
          3: {
            label: this.$pgettext('offer_card_option_label_segment_3', 'Opening fee'),
            value: this.$pgettext('offer_card_option_value_segment_3', 'OPENING_FEE')
          }
        },
        fillApplication: {
          2: {
            label: this.$pgettext('fill_application', 'Fill application'),
            value: this.$pgettext('pre_filled', 'Pre-filled')
          },
          3: {
            label: this.$pgettext('offer_card_option_label_segment_3', 'Fill application'),
            value: this.$pgettext('offer_card_option_value_segment_3', 'Pre-filled')
          }
        },
        accountManagementFee: {
          2: {
            label: this.$gettext('Account management fee'),
            value: this.$pgettext('offer_card', 'ACCOUNT_MANAGEMENT_FEE')
          },
          3: {
            label: this.$pgettext('offer_card_option_label_segment_3', 'Account management fee'),
            value: this.$pgettext('offer_card_option_value_segment_3', 'ACCOUNT_MANAGEMENT_FEE')
          }
        },
        agreeToContinue: this.$pgettext('offer_card', 'By continuing, I agree that Bigbank will collect and process information from other creditors about my previous credits')
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale']),
    props () {
      const hasSpecificComponent = componentMap[this.$store.state.channel]
      const commonProps = {
        title: this.title,
        lead: this.lead,
        options: this.options,
        agree: this.agree
      }

      return {
        index: this.index,
        count: this.count,
        offer: this.offer,
        currency: this.currency,
        ...(!hasSpecificComponent ? commonProps : {})
      }
    },
    options () {
      return [{
        title: this.openingFee.label.toUpperCase(),
        value: this.openingFee.value,
        visible: this.isSegment([2, 3])
      }, {
        title: this.fillApplication.label.toUpperCase(),
        value: this.fillApplication.value,
        visible: this.isSegment([2, 3]) && this.channelIsFi
      }, {
        title: this.accountManagementFee.label.toUpperCase(),
        value: this.accountManagementFee.value,
        visible: this.isSegment([2, 3]) && !this.channelIsFi
      }]
    },
    agree () {
      if (this.isSegment([1]) &&
        this.learnAboutCollectingOfInfoLink &&
        this.learnAboutCollectingOfInfoLink.length > 0
      ) {
        return {
          link: this.learnAboutCollectingOfInfoLink
        }
      } else {
        return null
      }
    },
    title () {
      return this.translations.title[this.offer.segmentCode]
    },
    lead () {
      return this.translations.lead[this.offer.segmentCode]
    },
    openingFee () {
      const openingFee = this.translations.openingFee[this.offer.segmentCode] || { label: '', value: '' }

      if (openingFee.value === 'OPENING_FEE') {
        const amount = this.$store.state.channel === 'FI' ? 0 : 50
        openingFee.value = formatMoneyWithCurrency(amount, this.currency, this.locale)
      }

      return openingFee
    },
    fillApplication () {
      return this.translations.fillApplication[this.offer.segmentCode] || { label: '', value: '' }
    },
    accountManagementFee () {
      const accountManagementFee = this.translations.accountManagementFee[this.offer.segmentCode] || { label: '', value: '' }

      if (accountManagementFee.value === 'ACCOUNT_MANAGEMENT_FEE') {
        accountManagementFee.value = formatMoneyWithCurrency(0, this.currency, this.locale)
      }

      return accountManagementFee
    },
    channelIsFi () {
      return this.$store.state.channel === 'FI'
    },
    upsellCardComponent () {
      return componentMap[this.$store.state.channel] || componentMap.DEFAULT
    },
    goToApplication () {
      return this.offer.upsellStartUrl
    },
    learnAboutCollectingOfInfoLink () {
      switch (this.$store.state.channel.toUpperCase()) {
        case 'FI':
          return 'https://www.bigbank.fi/modals/asiakastietojen-kasittelya-koskevat-periaatteet/'
        case 'SE':
          return ''
        case 'LT':
          return 'https://static.bigbank.lt/assets/2021/03/12132207/Klientu-duomenu-tvarkymo-principai-1.pdf'
        case 'LV':
          return ''
      }

      return ''
    }
  },
  methods: {
    apply () {
      const action = applyActions[this.offer.segmentCode]
      this.$tracker.action(action, undefined, undefined, true)
      window.open(this.goToApplication, '_blank')
    },
    isSegment (segments) {
      if (!Array.isArray(segments)) {
        segments = [segments]
      }
      return segments.includes(this.offer.segmentCode)
    }
  },
  mounted () {
    this.$tracker.action(viewActions[this.offer.segmentCode])
  }
}
</script>
<style lang="scss">
  .offer-card {
    &__contents {
      font-family: $gotham-book;
      transition: color 1s linear;
      padding: 15px 0;

      @media (min-width: $desktop-view-breaking-point + 1) {
        padding: unset;
      }

      &-title {
        font-size: $mobile-default-font-size;
        font-family: $gotham-bold;
        color: $blue;
        margin-top: -14px;
        margin-bottom: 8px;

        @media (min-width: $desktop-view-breaking-point) {
          margin-top: -18px;
          font-size: $default-font-size;
        }
      }

      &-description {
        font-size: $font-size-smallest;
        color: $gray-90;
      }

      &-agree-text {
        margin-top: 20px;
        font-size: $font-size-smallest;
        color: $gray-80;

        a {
          color: $blue;
        }
      }

      &-list {
        margin-bottom: -5px;

        @media (min-width: $desktop-view-breaking-point + 1) {
          margin-bottom: -10px;
        }
      }
    }
  }

  .card-links {
    padding-left: 25px;
    margin: auto 0;
    text-align: center;
    flex: 1 0 auto;
    transition: color 1s linear;

    @media (min-width: $desktop-view-breaking-point + 1) {
      min-width: 250px;
      max-width: 250px;
    }

    .bb-button--link {
      font-size: $font-size-small;
      margin: 10px auto 0;
      text-decoration: underline;
    }

    .bb-button {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    &--go-to-application {
      transition: border 1s, color 1s, background-color 1s linear;

      @media (max-width: $desktop-view-breaking-point + 1) {
        margin-top: 20px !important;
      }
    }
  }
</style>
