<template>
  <bb-modal
    :visible="isModalVisible"
    flavor="card"
    @close="onModalClosed"
    class="credit-card-already-ordered-modal noselect text-center"
    centerVertically
    full-mobile
    publicSize="m"
  >
    <h5 class="text-center modal-title">{{ modalTitle }}</h5>
    <p class="text-center m-t-20 modal-description" v-sanitize.basic="translations.description" />
    <div class="credit-card-already-ordered-modal__info-box bg-gray-10 m-t-40 m-b-30 text-left f-small f-color-navy">
      <p class="m-b-15">{{ translations.reasonText }}</p>
      <p class="m-b-15">{{ translations.sentToText }}</p>
      <p>{{  translations.contactAddress }} <br />
        <span class="address">{{ deliveryAddress }}</span>
        <bb-tooltip class="m-l-10"><span v-sanitize.basic="translations.tooltip" /></bb-tooltip>
      </p>
    </div>
    <template #footer>
      <bb-button
        v-if="$screen.md"
        display="block"
        name="close-replace"
        v-bind="submitButtonProps"
        @click="onModalClosed"
      />
      <bb-button
        v-else
        corner="sharp"
        display="block"
        name="close-replace"
        v-bind="submitButtonProps"
        @click="onModalClosed"
        size="lg"
        />
    </template>
  </bb-modal>
</template>

<script>
import '@bigbank/interface-components/dist/svg/illustration/cvv'
import CardTypeTranslationMixin from '../mixins/CardTypeTranslationMixin'
import CreditCardModalContentMixin from '@credit-card/components/mixins/CreditCardModalContentMixin'
import CustomerAddressTextLabelMixin from '@/modules/account/components/mixins/CustomerAddressTextLabelMixin'
import { translationPicker } from '@/plugins/translations'
import { mapState } from 'pinia'
import { useRootStore } from '../../../../store/root'

export default {
  name: 'credit-card-replace-modal',
  mixins: [
    CardTypeTranslationMixin,
    CreditCardModalContentMixin,
    CustomerAddressTextLabelMixin
  ],
  props: {
    visible: { required: true, type: Boolean },
    cardId: { required: true, type: Number },
    card: { required: true, type: Object }
  },
  data () {
    return {
      isModalVisible: this.visible
    }
  },
  computed: {
    ...mapState(useRootStore, ['language', 'channelConfig']),
    translations () {
      return {
        modalTitle: this.$pgettext('credit_card_replace_title', 'Replace the card'),
        buttonText: this.$pgettext('credit_card_replace', 'Close'),
        reasonText: this.$pgettext('credit_card_replace', 'Replacement card has been ordered.'),
        sentToText: this.$pgettext('credit_card_replace', 'The card will be sent to your:'),
        contactAddress: this.$pgettext('credit_card_replace', 'Contact address -'),
        description: this.$gettextInterpolate(this.$pgettext('credit_card_replace', 'You can\'t replace <strong>%{cardType} %{maskedPan}</strong> because:'), {
          cardType: this.cardTypeLabel(this.card),
          maskedPan: this.card.maskedPan
        }),
        tooltip: this.$gettextInterpolate(this.$pgettext('credit_card_replace', ' You can’t change the address of already ordered card. In case of the issues with the provided address or card not being delivered in approximately 2 weeks, please contact the <a target="_blank" href="%{helpUrl}">customer service</a>.'), {
          helpUrl: translationPicker(this.channelConfig.helpUrl, this.lang)
        })
      }
    },
    submitButtonText () {
      return this.translations.buttonText
    },
    deliveryAddress () {
      if (this.card.deliveryAddress) {
        const { address, city, country, zip } = this.card.deliveryAddress
        return [country, address, zip, city].filter(item => !!item).join(', ')
      } else {
        return this.customerContactAddress
      }
    },
    submitButtonProps () {
      const label = this.submitButtonText

      return {
        label
      }
    }
  }
}
</script>

<style lang="scss">
.credit-card-already-ordered-modal {
  .modal-title {
    color: $blue;
    font-weight: 500;
    font-size: $font-size-small;
    font-family: $gotham-medium;
  }

  .modal-description {
    color: $gray;
    font-size: $h4-size;

    strong,
    b {
      font-family: $gotham-medium !important // Don't blame me, IC has also important, can not override otherwise;
    }
  }

  &__info-box {
    padding: 16px;
  }

  .address {
    font-size: $default-font-size;
    font-family: $gotham-medium;
  }
}
</style>
