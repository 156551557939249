<template>
  <div>
    <bb-row class="m-b-20 m-b-sm-30">
      <bb-col>
        <div class="m-b-15 m-b-md-0 color-gray f-gotham-medium f-small">{{ computedTranslations.period }}</div>
        <bb-radio
          v-model="val.period"
          :options="filteringPeriods"
          :disabled="areFilterInputsDisabled"
          @change="() => onPeriodChange()"
          display="link"
          name="period-radio"
          class="hidden-max-md"
        />
        <bb-select
          v-model="val.period"
          :options="filteringPeriods"
          :disabled="areFilterInputsDisabled"
          :noempty="true"
          @change="() => onPeriodChange()"
          class="hidden-min-md p-t-0"
          name="period-select"
          framed
        />
      </bb-col>
    </bb-row>
    <bb-row class="m-b-20 m-b-sm-30">
      <bb-col>
        <div class="m-b-15 color-gray f-gotham-medium f-small">{{ computedTranslations.search }}</div>
        <bb-fieldset-repeater
          v-model="val.advancedSearchFilters"
          :rowLimit="maxFiltersCount + 1"
          :emptyRowModel="advancedFilterEmptyRowModel"
          :add-button-label="computedTranslations.addMore"
          :bleed="false"
          :disableAdding="isAddingNewFiltersDisabled"
          condensed
          headerless
        >
          <template #fields="row">
            <bb-row gutter="20">
              <span class="hidden">
                  {{ row.model.filterFunction = row.model.filterFunction || transactionsSettings }}
              </span>
              <bb-col col="24" md="12" class="m-b-5 m-b-md-0">
                <bb-input
                  v-model="row.model.filterValue"
                  :name="`filterValue${row.id}`"
                  :disabled="areFilterInputsDisabled"
                  :placeholder="computedTranslations.typeHereToSearch" framed class="p-0"/>
              </bb-col>
              <bb-col col="24" md="12" class="m-b-5 m-b-md-0">
                <bb-select
                  v-model="row.model.filterFunction"
                  :name="`filterFunction${row.id}`"
                  :disabled="isLoading"
                  :options="filteringAdvancedSearchOptions"
                  noempty
                  framed
                  class="p-0"
                />
              </bb-col>
            </bb-row>
          </template>
        </bb-fieldset-repeater>
      </bb-col>
    </bb-row>
    <bb-row class="m-b-10">
      <bb-col class="p-l-lg-5">
        <bb-checkbox
          v-for="(checkbox) in additionalOptionsCheckboxes"
          :name="checkbox.name"
          :key="checkbox.name"
          v-model="val.additionalOptionsCheckboxes[checkbox.name]"
          :disabled="checkbox.disabled"
          size="small"
          class="d-inline-block"
        >{{ checkbox.label }}</bb-checkbox>
      </bb-col>
    </bb-row>
  </div>
</template>

<script>
import { TransactionsPeriodFilter, TransactionAdvancedSearchOption } from '@bigbank/dc-common/enums/credit-card.enums'
import { capitalize } from 'lodash'
import { getTranslation } from '@/plugins/translations'
import { BbFieldsetRepeater } from '@bigbank/interface-components'

export default {
  name: 'advanced-transaction-filter',
  components: {
    BbFieldsetRepeater
  },
  props: {
    value: { // used with v-model
      type: Object,
      required: true
    },
    areFilterInputsDisabled: {
      type: Boolean,
      default: false
    },
    maxFiltersCount: {
      type: Number,
      default: 5
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    transactionsSettings: {
      type: String
    },
    additionalOptionsCheckboxes: {
      type: Array,
      default: () => []
    },
    translations: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      val: this.value || {
        period: null,
        filters: [],
        advancedSearchFilters: [],
        additionalOptionsCheckboxes: {}
      }
    }
  },
  watch: {
    value (newVal) {
      this.val = newVal
    }
  },
  methods: {
    onInput () {
      this.$emit('input', this.val)
    },
    onPeriodChange () {
      this.onInput()
      this.$emit('on-period-change', this.val.period)
    }
  },
  computed: {
    computedTranslations () {
      return {
        search: this.$pgettext('credit_card_transactions_search', 'Search'),
        period: this.$pgettext('credit_card_transactions_search', 'Period'),
        addMore: this.$pgettext('credit_card_transactions_search', 'Add more'),
        typeHereToSearch: this.$pgettext('credit_card_transactions_search', 'Type here to search'),
        ...this.translations
      }
    },
    filteringPeriods () {
      return Object.values(TransactionsPeriodFilter).map((period) => {
        const defaultTranslation = capitalize(period.replace(/_/g, ' ').toLowerCase())

        return {
          value: period,
          text: getTranslation(period, ['TRANSACTION_SEARCH_PERIOD_FILTER'], defaultTranslation)
        }
      })
    },
    filteringAdvancedSearchOptions () {
      return Object.values(TransactionAdvancedSearchOption).map((option) => {
        const defaultTranslation = capitalize(option.split(/(?=[A-Z])/).join(' ').toLowerCase())

        return {
          value: option,
          text: getTranslation(defaultTranslation, ['TRANSACTION_ADVANCED_SEARCH_OPTION'], defaultTranslation)
        }
      })
    },
    advancedFilterEmptyRowModel () {
      return {
        filterValue: '',
        filterFunction: this.filteringAdvancedSearchOptions[0].value
      }
    },
    isAddingNewFiltersDisabled () {
      const hasTooManyFilters = this.val.advancedSearchFilters.length >= this.maxFiltersCount
      const arePreviousFiltersFilled = this.val.advancedSearchFilters.every(filter => (filter?.filterValue || '').trim().length > 0)

      return this.areFilterInputsDisabled || !arePreviousFiltersFilled || hasTooManyFilters
    }
  }
}
</script>
