import { AccountStatementTransactionType } from '@bigbank/dc-common/enums/credit-card.enums'
import { CREDIT_CARD, MY_TRANSACTIONS } from '@/const'

export const getEmptyAccountStatementData = (flavor) => ({
  transactions: [],
  transactionsTotal: flavor === 'account' ? MY_TRANSACTIONS.TRANSACTIONS_PER_PAGE : CREDIT_CARD.TRANSACTIONS_PER_PAGE,
  finalBalance: { date: null, value: 0 },
  startingBalance: { date: null, value: 0 },
  startingAvailableBalance: 0,
  finalAvailableBalance: 0
})

export const getEmptyFiltersFormData = (flavor) => {
  const emptyFormData = {
    startDate: null,
    endDate: null,
    period: null,
    advancedSearchFilters: [],
    additionalOptionsCheckboxes: {
      [AccountStatementTransactionType.Credit]: true,
      [AccountStatementTransactionType.Debit]: true,
      [AccountStatementTransactionType.Reservation]: true
    }
  }

  if (flavor === 'account') {
    emptyFormData.accountId = null
  } else if (flavor === 'cardId') {
    emptyFormData.cardId = null
  }

  return emptyFormData
}
