<template>
  <div>
    <bb-card size="fluid">
      <bb-container fluid class="promo">
        <bb-row>
          <bb-col sm="14">
            <h5 :class="{
             title: true,
             'color-gray': true,
             'm-t-5': isOrderVirtualCardButtonVisible,
             'm-t-20': !isOrderVirtualCardButtonVisible
            }">
              {{ translations[translationsPrefix].get_virtual_card_for_your_payments }}
            </h5>
            <p class="description m-b-md-10 m-t-10">
              {{ translations[translationsPrefix].get_virtual_card_for_your_payments_text }}</p>
            <div class="button-container">
              <div class="hide-mobile">
                <bb-button
                  class="button-order-virtual-card m-t-20"
                  display="block"
                  v-if="isOrderVirtualCardButtonVisible"
                  :loading="isLoading"
                  :disabled="isOrderVirtualCardButtonDisabled"
                  :label="translations[translationsPrefix].order_virtual_card"
                  @click="onOrderYourVirtualCardButtonClicked"
                  name="order-virtual-card"
                />
              </div>
            </div>
          </bb-col>
          <bb-col sm="10" class="d-flex align-items-center">
            <div class="advantages">
                <span
                  :key="index"
                  v-for="(translation, index) in advantages"
                >
                  <bb-icon
                    class="hide-mobile m-r-10"
                    size="24"
                    name="ui-check-circle-outline"
                    fill="green"
                  />
                  {{ translation }}
                </span>
            </div>
          </bb-col>
          <div class="hide-desktop m-t-20 text-center w-100">
            <bb-button
              class="button-order-virtual-card w-100"
              display="block"
              v-if="isOrderVirtualCardButtonVisible"
              :loading="isLoading"
              :disabled="isOrderVirtualCardButtonDisabled"
              :label="translations.order_virtual_card"
              @click="onOrderYourVirtualCardButtonClicked"
            />
          </div>
        </bb-row>
      </bb-container>
    </bb-card>
    <component
      v-if="isModalVisible"
      :is="modalComponent"
      v-bind="modalComponentProps"
      @onModalClosed="onModalClosed"
      @isLoading="setLoading"
    />
  </div>
</template>

<script>
import CreditCardOrderModal from '@credit-card/components/modals/CreditCardOrderModal'
import { mapState, mapActions } from 'pinia'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'
import { translationPicker } from '@/plugins/translations'
import { useRootStore } from '../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'
import { CardType } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'

export default {
  data () {
    return {
      isModalVisible: false,
      modalComponent: null,
      isLoading: false,
      isOrderVirtualCardButtonDisabled: false,
      limits: {
        canOrderVirtualCard: true
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['channel', 'language', 'featureFlags', 'locale', 'channelConfig']),
    ...mapState(useLoanStore, ['creditCardContract', 'creditCards']),
    ...mapState(useLoanStore, {
      loanFeatureFlags: 'featureFlags'
    }),
    contract () {
      return this.creditCardContract
    },
    translations () {
      return {
        credit_card_order_virtual_card: {
          advantages: {
            its_free: this.$pgettext('credit_card_order_virtual_card', 'It\'s free'),
            instant_use: this.$pgettext('credit_card_order_virtual_card', 'Instant use'),
            protects_privacy: this.$pgettext('credit_card_order_virtual_card', 'Protects privacy')
          },
          order_virtual_card: this.$pgettext('credit_card_order_virtual_card', 'Order virtual card'),
          get_virtual_card_for_your_payments: this.$pgettext('credit_card_order_virtual_card', 'Get virtual card for your payments'),
          get_virtual_card_for_your_payments_text: this.$pgettext('credit_card_order_virtual_card', 'Get virtual card for free to protect your privacy')
        },
        credit_card_order_virtual_card_empty_state: {
          advantages: {
            its_free: this.$pgettext('credit_card_order_virtual_card_empty_state', 'It\'s free'),
            instant_use: this.$pgettext('credit_card_order_virtual_card_empty_state', 'Instant use'),
            protects_privacy: this.$pgettext('credit_card_order_virtual_card_empty_state', 'Protects privacy')
          },
          order_virtual_card: this.$pgettext('credit_card_order_virtual_card_empty_state', 'Order virtual card'),
          get_virtual_card_for_your_payments: this.$pgettext('credit_card_order_virtual_card_empty_state', 'Get virtual card for your payments'),
          get_virtual_card_for_your_payments_text: this.$pgettext('credit_card_order_virtual_card_empty_state', 'Get virtual card for free to protect your privacy')
        },
        monthly_limit_learn_more: this.$pgettext('account_verification', 'Learn more'),
        activation_warning: this.$pgettext(
          'credit_card_order_virtual_card',
          'Unfortunately you can not order a virtual card, because you do not have enough monthly transaction limit.'
        )
      }
    },
    translationsPrefix () {
      return this.isOrderVirtualCardButtonVisible
        ? 'credit_card_order_virtual_card'
        : 'credit_card_order_virtual_card_empty_state'
    },
    advantages () {
      return [
        this.translations[this.translationsPrefix].advantages.its_free,
        this.translations[this.translationsPrefix].advantages.instant_use,
        this.translations[this.translationsPrefix].advantages.protects_privacy
      ]
    },
    modalComponentProps () {
      return {
        visible: this.isModalVisible
      }
    },
    hasPhysicalCard () {
      return this.creditCards
        .filter(({ type }) => type === CardType.PHYSICAL_AND_VIRTUAL)
        .length > 0
    },
    isOrderVirtualCardButtonVisible () {
      if (!this.hasPhysicalCard && this.loanFeatureFlags.virtualCardOrderingWithoutButton) {
        return false
      }
      if (this.hasPhysicalCard || this.loanFeatureFlags.virtualCardOrderingWithoutButton) {
        return true
      }

      return this.hasPhysicalCard
    }
  },
  methods: {
    ...mapActions(useLoanStore, ['getAvailableMonthlyLimit']),
    setModalComponent (component) { this.modalComponent = component },
    toggleModalVisibility (flag) { this.isModalVisible = flag },
    toggleOrderVirtualCardButtonDisabled (flag) { this.isOrderVirtualCardButtonDisabled = flag },
    setLoading (flag) { this.isLoading = flag },
    async onOrderYourVirtualCardButtonClicked () {
      this.setLoading(true)
      this.toggleOrderVirtualCardButtonDisabled(true)

      if (this.featureFlags.enableUnverifiedCustomerAdditionalLimits) {
        this.limits = await this.getAvailableMonthlyLimit()
        if (!this.limits.canOrderVirtualCard) {
          this.$emit('close')
          this.$notify({
            text: this.translations.activation_warning,
            type: 'warning',
            link: {
              name: this.translations.monthly_limit_learn_more,
              route: 'account/verification',
              visible: true
            }
          })
          this.setLoading(false)
          return
        }
      }

      if (this.contract) {
        this.setLoading(false)
        this.setModalComponent(CreditCardOrderModal)
        this.toggleModalVisibility(true)
        return
      }

      window.open(
        translationPicker(this.channelConfig.applyLoanUrl[InternalLoanType.CREDIT_CARD], this.lang),
        '_blank'
      )
      this.setLoading(false)
    },
    onModalClosed () {
      this.setModalComponent(null)
      this.toggleModalVisibility(false)
      this.toggleOrderVirtualCardButtonDisabled(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.promo {
  text-align: center;

  @media (min-width: $breakpoint-sm) {
    text-align: initial;
    padding-left: 0;
  }
}

.title {
  @media (min-width: $breakpoint-sm) {
    font-family: $gotham-medium;
    font-size: $h4-size;
  }
}

.description {
  color: $gray-80;
  font-size: $font-size-default-mobile;

  @media (min-width: $breakpoint-sm) {
    font-family: $gotham-bold;
    color: $gray-70;
    text-transform: uppercase;
    font-size: $font-size-tiny;
  }
}

.button-container {
  display: flex;
  justify-content: flex-start;
}

.button-order-virtual-card {
  margin: 0 auto;
}

.advantages {
  font-size: $font-size-small;
  color: $gray;
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: space-evenly;

  @media (max-width: $breakpoint-sm) {
    margin: 10px auto 20px;
    font-size: $default-font-size;
  }

  span {
    @media (min-width: $breakpoint-sm) {
      font-family: $gotham-medium;
    }
  }
}

.hide-mobile {
  display: none;

  @media (min-width: $breakpoint-sm) {
    display: inline;
  }
}

.hide-desktop {
  @media (min-width: $breakpoint-sm) {
    display: none;
  }
}
</style>
