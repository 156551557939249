 <template>
  <component :is="layout">
    <bb-error-page @clickButton="redirectToLogin" v-bind="error" class="p-l-20 p-r-20" />
    <div class="f-color-gray-80 f-tiny correlation-id m-t-20" v-if="correlationId">
      {{translations.errCodeInfo}} <strong>{{correlationId}}</strong>
    </div>
  </component>
</template>

<script>
import '@bigbank/interface-components/dist/svg/illustration/error500'
import '@bigbank/interface-components/dist/svg/illustration/error404'
import '@bigbank/interface-components/dist/svg/illustration/no-account'
import '@bigbank/interface-components/dist/svg/illustration/session-expired'
import '@bigbank/interface-components/dist/svg/illustration/no-connection'
import '@bigbank/interface-components/dist/svg/illustration/maintenance'
import '@bigbank/interface-components/dist/svg/illustration/no-offers'
import { mapState } from 'pinia'
import { translationPicker } from '@/plugins/translations'
import ErrorViewName from '@/types/error-view-name'
import isUndefined from 'lodash/isUndefined'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'
import LogoutReason from '@/types/logout-reason'
import { useRootStore } from '../store/root'

export default {
  props: ['payload'],
  computed: {
    ...mapState(useRootStore, ['language', 'featureFlags', 'isMobile', 'loginUrl', 'channelConfig']),
    errorsList () {
      return {
        [ErrorViewName.HTTP_STATUS_500]: {
          icon: 'illustration-error500',
          title: this.$gettext('Sorry ... it\'s not you. It\'s us.'),
          description: this.$gettext('We are experiencing an internal server problem. Please try again later.'),
          buttonText: this.$gettext('Back to home'),
          linkText: this.$gettext('Need help?'),
          buttonPath: '/',
          linkPath: '/help'
        },
        [ErrorViewName.HTTP_STATUS_404]: {
          icon: 'illustration-error404',
          title: this.$gettext('Oops, we couldn\'t find what you are looking for.'),
          description: this.$gettext('You may have mistyped the address or the page may have moved.'),
          buttonText: this.$gettext('Back to home'),
          linkText: this.$gettext('Need help?'),
          buttonPath: '/',
          linkPath: '/help'
        },
        [ErrorViewName.NO_APPLICATIONS]: {
          icon: 'illustration-no-account',
          title: this.$gettext('Oops, you seem not to have an active application with us.'),
          description: this.$gettext('As soon as you have an active application, you will be able to login to self-service to view your status and other Bigbank offers.'),
          buttonText: this.featureFlags.disableApplyForALoan ? undefined : this.$gettext('Take a look at our offers'),
          linkText: '',
          buttonPath: isUndefined(this.channelConfig.applyLoanUrl) ? '/' : translationPicker(this.channelConfig.applyLoanUrl[InternalLoanType.LOAN], this.lang),
          buttonTarget: '_blank'
        },
        [ErrorViewName.NO_CUSTOMER]: {
          icon: 'illustration-no-account',
          title: this.$gettext('Oops, you seem to not have access'),
          description: this.$gettext('You may not have access in the future as well, so don\'t try again too soon please.'),
          buttonText: this.$gettext('Back to home'),
          linkText: '',
          buttonPath: '/home'
        },
        [ErrorViewName.NO_CONTRACT]: {
          icon: 'illustration-no-account',
          title: this.$gettext('Oops, you seem not to have an active contract with us.'),
          description: this.$gettext('As soon as you have an active contract, you will be able to view your contract information.'),
          buttonText: this.$gettext('Back to home'),
          linkText: '',
          buttonPath: '/home',
          layout: 'layout-with-menu'
        },
        [ErrorViewName.NO_ACCEPT]: {
          icon: 'illustration-no-account',
          title: this.$gettext('You no longer can access our Self Service.'),
          description: this.$gettext('If you still would like to track online your loan application status or lorem ipsum lorem ipsum, you can agree with out Terms and Conditions lorem ipsum.'),
          buttonText: this.$gettext('Logout'),
          linkText: this.$gettext('Back to home'),
          buttonPath: '#',
          linkPath: `/logout?reason=${LogoutReason.NO_ACCESS_ERROR_VIEW}`
        },
        [ErrorViewName.NO_ACCESS]: {
          icon: 'illustration-no-account',
          title: this.$gettext('Oops, you can\'t access your account right now.'),
          description: this.$gettextInterpolate(this.$gettext('You can\'t access to your account right now. Please call to our customer support: %{ n }.'),
            { n: this.channelConfig.phone }),
          buttonText: this.$gettext('Call our customer support'),
          linkText: '',
          buttonPath: translationPicker(this.channelConfig.helpUrl, this.lang) || '/home'
        },
        [ErrorViewName.SESSION_EXPIRED]: {
          icon: !this.isMobile ? 'illustration-session-expired' : undefined,
          title: this.$gettext('Your session has expired due to inactivity.'),
          description: this.$gettext('Any confirmed transactions are saved, but you will need to login again in order to lorem ipsum.'),
          buttonText: this.$gettext('Login'),
          linkText: this.$gettext('Back to home'),
          buttonPath: `/logout?redirectToLogin&reason=${LogoutReason.SESSION_EXPIRED_ERROR_VIEW}`,
          linkPath: translationPicker(this.channelConfig.helpUrl, this.lang) || '/home'
        },
        [ErrorViewName.NO_CONNECTION]: {
          icon: 'illustration-no-connection',
          title: this.$gettext('Oops, your connection seems off.'),
          description: this.$gettext('Slow or no internet connection. Let’s give it another shot!'),
          buttonText: this.$gettext('Retry'),
          linkText: this.$gettext('Need help?'),
          buttonPath: '/',
          linkPath: '/help'
        },
        [ErrorViewName.MAINTENANCE]: {
          icon: 'illustration-maintenance',
          title: this.$gettext('We are working to improve your experience.'),
          description: this.$gettext('Bigbank is currently unavailable as we build up our infrastructure in order to provide better service to all of our clients. Please try back later. Thank you for your patience!'),
          buttonText: '',
          linkText: ''
        },
        [ErrorViewName.NO_OFFERS]: {
          icon: 'illustration-no-offers',
          title: this.$gettext('No personal offers for you at the moment.'),
          description: this.$gettext('Lorem ipsum dolor sit amet, consectetur adipisc'),
          buttonText: '',
          linkText: ''
        }
      }
    },
    error () {
      if (this.payload) {
        return this.errorsList[this.payload.errorCode]
      } else {
        return this.errorsList[this.$route.params.errorCode]
      }
    },
    layout () {
      return this.error && this.error.layout ? this.error.layout : 'layout-with-navbar'
    },
    translations () {
      return {
        errCodeInfo: this.$pgettext('error_code_info', 'Please try again and if this problem persists, please contact us and provide the following error code:')
      }
    },
    correlationId () {
      return this.payload?.params?.correlationId
    }
  },
  methods: {
    redirectToLogin () {
      window.location = '/'
    }
  }
}
</script>

<style lang="scss">
.correlation-id {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
  max-width: 500px;
}
</style>
