<template>
  <div class="payment-frequency-container m-t-30">
    <bb-radio name="payment-frequency"
              equalWidth
              :options="options"
              :label="translations.question"
              :value="value"
              :selected="value"
              @input="input"/>
  </div>
</template>

<script>
import { BbRadio } from '@bigbank/interface-components'

export default {
  name: 'SavingDepositPaymentFrequency',
  components: {
    BbRadio
  },
  data () {
    return {
      translations: {
        month: this.$gettext('Once a Month'),
        year: this.$gettext('Once a Year'),
        question: this.$gettext('I want my interest payment frequency to be') + ':'
      }
    }
  },
  props: {
    value: String,
    validPaymentFrequencies: {
      type: Array,
      required: false
    }
  },
  computed: {
    options () {
      const paymentOptions = []
      if (this.validPaymentFrequencies.includes('YEAR')) {
        paymentOptions.push({
          value: 'YEAR',
          text: this.translations.year
        })
      }

      if (this.validPaymentFrequencies.includes('MONTH')) {
        paymentOptions.push({
          value: 'MONTH',
          text: this.translations.month
        })
      }

      return paymentOptions
    }
  },
  methods: {
    input (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped lang="scss">
.payment-frequency-container {
  max-width: 380px;
}
</style>
