<template>
  <layout-with-menu :loading="loading">
    <div class="deposit">
      <hero-header
        v-if="isHeroHeaderVisible"
        product="TERM"
        :state="state"
        :username="username"
        :totalSavings="totalSavingsFloat"
        :currency="currencyCode"
      />
      <div v-else class="corporate-gap-when-hero-header-is-missing"/>
      <div class="deposit__content">
        <horizontal-actions-menu
          class="deposit__content__menu"
          v-bind="{ tabRoutes }"/>
        <bb-card
          v-if="isAccountLimitNotificationVisible"
          size="fluid"
          class="m-b-35"
          data-testid="deposit-account-verification-notification"
        >
          <div class="f-gotham-medium f-color-gray m-b-15">
            {{ translations.unverified_title }}
          </div>
          <div class="f-gotham-book f-smallest">
            <span v-sanitize.basic="translations.unverified_text" />
            <bb-link :to="{ name: AccountRouteName.AccountVerification }" class="f-nowrap">
              {{ translations.learn_more }}
            </bb-link>
          </div>
        </bb-card>
        <template v-if="!isTransactionsView">
          <corporate-clarification-banner
            v-if="visibleDeposits.length > 0 && isCompany"
            product="deposit"
            class="m-b-35"
          />
          <div class="deposit__content__cards"
               :class="{company: isCompany, empty: visibleDeposits.length <= 0 && !isOfferCardVisible }">
            <deposit-card
              v-for="(deposit, index) in visibleDeposits"
              :key="deposit.id"
              :deposit="deposit"
              :depositCount="visibleDeposits.length"
              :depositIndex="index" />
            <offer-card v-if="isOfferCardVisible" :card="offer" />
          </div>
          <campaign-card :campaigns="cardList" />
          <box-with-icon
            v-if="visibleDeposits.length < 1"
            icon="client-empty-cards">
            <span v-sanitize.basic="setEmptyBoxTranslations" />
          </box-with-icon>
        </template>
        <transactions
          v-if="showTransactions"
          v-bind="transactionsProps"
          :key="activeTab"
        />
      </div>
    </div>
    <router-view />
    <campaign-modal
      :visible="popupCampaignsVisible"
      :campaigns="popupCampaigns"
      @close="closeCampaignModal"
    />
  </layout-with-menu>
</template>

<script>
import { useAccountStore } from '@account/store/accountStore'
import HeroHeader from '../components/HeroHeader'
import DepositCard from '../components/DepositCard'
import HorizontalActionsMenu from '../components/HorizontalActionsMenu'
import { mapActions, mapState, mapWritableState } from 'pinia'
import BoxWithIcon from '@/components/BoxWithIcon'
import FlowMixin from '../mixins/flowMixin'
import CampaignModalMixin from '../../../mixins/campaignModalMixin'
import CampaignCardsMixin from '../../../mixins/campaignCardsMixin'
import OfferCard from '@/components/cards/OfferCard'
import CampaignCard from '@/components/cards/CampaignCard'
import CampaignModal from '@/components/CampaignModal'
import Transactions from '../components/Transactions'
import DebtNotificationMixin from '../../../mixins/debtNotificationMixin'
import CorporateClarificationBanner from '../../../components/corporate/CorporateClarificationBanner'
import '@bigbank/interface-components/dist/svg/client/empty-cards'
import { useDepositStore } from '@deposits/store/depositStore'
import { DepositType, DepositRouteName } from '@deposits/const'
import { DEPOSITS } from '@/const'
import { AccountRouteName } from '@account/const'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { useRootStore } from '../../../store/root'
import dayjs from 'dayjs'

const TabNames = Object.freeze({
  active: 'active',
  closed: 'closed',
  transactions: 'transactions'
})

export default {
  name: 'deposits-view',
  components: {
    HeroHeader,
    DepositCard,
    HorizontalActionsMenu,
    BoxWithIcon,
    OfferCard,
    CampaignModal,
    CampaignCard,
    Transactions,
    CorporateClarificationBanner
  },
  mixins: [
    FlowMixin,
    CampaignModalMixin,
    CampaignCardsMixin,
    DebtNotificationMixin
  ],
  created () {
    this.fetchDeposits(this.deposits && this.deposits.length > 0)
  },
  data () {
    return {
      activeTab: TabNames.active,
      TabNames,
      AccountRouteName
    }
  },
  watch: {
    $route (to) {
      this.activeTab = to.params.status || TabNames.active
    },
    activeTab: {
      async handler () {
        if (this.isAccountLimitNotificationVisible) {
          this.loading = true
          await this.getVerificationData({ forceReload: true })
          this.loading = false
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState(useRootStore, { rootFeatureFlags: 'featureFlags' }),
    ...mapState(useRootStore, ['username', 'isCompany', 'currency', 'locale']),
    ...mapState(useDepositStore, [
      'deposits',
      'depositsByProductAndCategory',
      'activeDepositsByProduct',
      'activeDepositCountByProduct'
    ]),
    ...mapState(useAccountStore, ['verificationData']),
    ...mapWritableState(useDepositStore, ['loading']),
    isActiveView () {
      return this.activeTab === TabNames.active
    },
    isClosedView () {
      return this.activeTab === TabNames.closed
    },
    isTransactionsView () {
      return this.activeTab === TabNames.transactions
    },
    visibleDeposits () {
      return this.depositsByCategory[this.activeTab]
    },
    allDeposits () {
      return [
        ...this.depositsByCategory.active,
        ...this.depositsByCategory.closed
      ]
    },
    showTransactions () {
      return !this.isClosedView
    },
    depositsByCategory () {
      return this.depositsByProductAndCategory[DepositType.Term]
    },
    totalSavingsFloat () {
      return this.activeDepositsByProduct[DepositType.Term].reduce((total, deposit) => deposit.availableBalance + total, 0)
    },
    state () {
      return this.activeDepositCountByProduct[DepositType.Term] > 0 ? 'activeContracts' : 'noActiveContracts'
    },
    tabRoutes () {
      return [
        {
          tab: TabNames.active,
          title: this.$pgettext('deposit_tabs', 'My deposits'),
          active: this.isActiveView,
          path: { name: DepositRouteName.TermDeposits, params: { status: null, depositType: this.$route.params.depositType } }
        },
        {
          tab: TabNames.closed,
          title: this.$pgettext('deposit_tabs', 'Closed contracts'),
          active: this.isClosedView,
          path: { name: DepositRouteName.TermDeposits, params: { status: TabNames.closed } }
        },
        {
          tab: TabNames.transactions,
          title: this.$pgettext('deposit_tabs', 'Transactions'),
          active: this.isTransactionsView,
          path: { name: DepositRouteName.TermDeposits, params: { status: TabNames.transactions } }
        }
      ]
    },
    currencyCode () {
      return (this.deposits && this.deposits.length && this.deposits[0].currencyCode) || this.currency
    },
    isHeroHeaderVisible () {
      return !this.isCompany
    },
    isOfferCardVisible () {
      if (this.isCompany) {
        return false
      }

      return this.isActiveView && this.depositsByCategory.active.length <= 0
    },
    offer () {
      return {
        title: this.$pgettext('offer_card', 'Great to have you back.'),
        description: this.$pgettext('offer_card', 'You don\'t have any active deposits, but please check out our offers.'),
        buttonText: this.$pgettext('offer_card', 'Check Our Offers'),
        to: { name: DepositRouteName.New }
      }
    },
    cardList () {
      if (this.isCompany) {
        return []
      }
      if (this.campaignsForCards[0] && Array.isArray(this.campaignsForCards[0].campaigns)) {
        return [...this.campaignsForCards[0].campaigns]
      }

      return []
    },
    transactionsProps () {
      return {
        deposits: this.isTransactionsView ? this.allDeposits : this.visibleDeposits,
        'show-reserved-transactions': false,
        visibleTransactionsCount: !this.isTransactionsView ? 3 : undefined,
        showHistoryButton: !this.isTransactionsView
          ? {
              to: { name: DepositRouteName.TermDeposits, params: { status: TabNames.transactions } },
              label: this.translations.showHistory
            }
          : undefined
      }
    },
    translations () {
      return {
        showHistory: this.$pgettext('deposit_dashboard_transactions', 'Show history'),
        unverified_title: this.$pgettext('account_verification_deposit', 'Deposit contract end is approaching'),
        unverified_text: this.$pgettext(
          'account_verification_deposit',
          'Your available limit for the transactions ' +
            'in one calendar month is {amount}. In order to withdraw the deposit, you should remove the limit ' +
            'by verifying your account.'
        )
          .replace(
            '{amount}',
            `<span class="f-nowrap">${formatMoneyWithCurrency(this.verificationData.availableAmount, this.currency, this.locale, { fractionDigits: 0 })}</span>`
          ),
        learn_more: this.$pgettext('account_verification_deposit', 'Learn more'),
        closedViewMessage: this.$pgettext('deposit_dashboard', 'As soon as you have closed contracts, they will be available here.'),
        emptyViewCorporateMessage: this.$pgettext('deposit_dashboard', 'Message that will tell you that you don\'t have anything to show.')

      }
    },
    isAccountLimitNotificationVisible () {
      const conditions = [
        this.rootFeatureFlags.enableUnverifiedCustomerAdditionalLimits,
        !this.verificationData.isF2fIdentified,
        this.isActiveView
      ]

      if (conditions.includes(false)) {
        return false
      }

      return this.depositsByCategory.active.some(deposit => {
        const endDate = dayjs(deposit.endDate)
        const today = dayjs()
        const monthsRemaining = endDate.diff(today, 'month')
        const isEndingSoon = monthsRemaining <= DEPOSITS.MONTHS_BEFORE_WITHDRAW
        const isExceedingLimit = deposit.amount > this.verificationData.availableAmount

        return isEndingSoon && isExceedingLimit
      })
    },
    setEmptyBoxTranslations () {
      if (this.isCompany && !this.isClosedView) {
        return this.translations.emptyViewCorporateMessage
      }

      return this.translations.closedViewMessage
    }
  },
  methods: {
    ...mapActions(useDepositStore, ['fetchDeposits']),
    ...mapActions(useAccountStore, ['getVerificationData']),
    newDeposit ($event) {
      this.startApplicationFlow($event)
    }
  },
  mounted () {
    this.activeTab = this.$route.params.status || TabNames.active
  }
}
</script>

<style lang="scss">
.deposit {
  &__content {
    margin: auto;
    width: 90%;
    max-width: 850px;

    &__menu {
      @media (min-width: $desktop-view-breaking-point) {
        margin-top: 20px;
        margin-bottom: 35px;
      }
    }

    &__cards {
      margin-top: -45px;

      &.empty {
        margin-top: 30px;
      }

      &.company {
        margin-top: 20px;
      }

      @media (min-width: $desktop-view-breaking-point) {
        margin-top: 0;
      }
    }
  }
}
</style>
