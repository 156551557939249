<template>
    <bb-application-contract-card
    v-bind="cardPops"
    @primaryButtonClick="openOutpaymentCancellationModal"
  >
    <template #title>
      <span class="color-gray-70 f-medium f-case-upper f-small-lg">
        {{ translations.transferTo }}
        <span class="color-gray f-gotham-bold">
          {{ outpayment.recipientIban }}
        </span>
      </span>
    </template>
  </bb-application-contract-card>
</template>

<script>

import { BbApplicationContractCard } from '@bigbank/interface-components'
import { mapState } from 'pinia'
import { useRootStore } from '@/store/root'
import { useDepositStore } from '@deposits/store/depositStore'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { DepositRouteName } from '@deposits/const'

export default {
  name: 'outpayment-cancellation-deposit-card',
  components: {
    BbApplicationContractCard
  },
  props: {
    outpayment: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showOutpaymentCancellationModal: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale', 'currency', 'isCompany']),
    ...mapState(useDepositStore, ['defaultDemandDeposit']),
    translations () {
      if (this.isCompany) {
        return {
          amount: this.$pgettext('cancel_SD_reservation_corporate', 'Amount'),
          issueDate: this.$pgettext('cancel_SD_reservation_corporate', 'Issue date'),
          payoutDate: this.$pgettext('cancel_SD_reservation_corporate', 'Payout date'),
          cancelOutpayment: this.$pgettext('cancel_SD_reservation_corporate', 'Cancel withdrawal'),
          transferTo: this.$pgettext('cancel_SD_reservation_corporate', 'Transfer to'),
          tooltip: this.$pgettext('cancel_SD_reservation_corporate', 'Your money will be transferred on this date')
        }
      }

      return {
        amount: this.$pgettext('cancel_SD_reservation_private', 'Amount'),
        issueDate: this.$pgettext('cancel_SD_reservation_private', 'Issue date'),
        payoutDate: this.$pgettext('cancel_SD_reservation_private', 'Payout date'),
        cancelOutpayment: this.$pgettext('cancel_SD_reservation_private', 'Cancel withdrawal'),
        transferTo: this.$pgettext('cancel_SD_reservation_private', 'Transfer to'),
        tooltip: this.$pgettext('cancel_SD_reservation_private', 'Your money will be transferred on this date')
      }
    },
    cardPops () {
      return {
        type: 'depo',
        showAmountOnMobile: true,
        flavorAgnosticProgress: true,
        separator: true,
        primaryButton: {
          label: this.translations.cancelOutpayment,
          inverted: true
        },
        sections: [
          {
            label: this.translations.amount,
            content: formatMoneyWithCurrency(this.outpayment.amount, this.currency, this.locale)
          },
          {
            label: this.translations.issueDate,
            content: this.outpayment.issueDate
          },
          {
            label: this.translations.payoutDate,
            content: this.outpayment.payoutDate,
            tooltip: this.translations.tooltip
          }
        ]
      }
    }
  },
  methods: {
    openOutpaymentCancellationModal () {
      this.$router.push({
        name: DepositRouteName.DemandDepositsCancelOutpayment,
        query: {
          reservationId: this.outpayment.id
        }
      })
    }
  }
}
</script>
