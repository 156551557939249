import { render, staticRenderFns } from "./SavingDepositPaymentFrequency.vue?vue&type=template&id=62cafc6a&scoped=true&"
import script from "./SavingDepositPaymentFrequency.vue?vue&type=script&lang=js&"
export * from "./SavingDepositPaymentFrequency.vue?vue&type=script&lang=js&"
import style0 from "./SavingDepositPaymentFrequency.vue?vue&type=style&index=0&id=62cafc6a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62cafc6a",
  null
  
)

export default component.exports