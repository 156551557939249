import DEPO_STATES from './modules/deposits/utils/depositStates'
import { DepositType } from '@deposits/const'

export const TERMS_AND_CONDITIONS = {
  DECLINE_TERMS: 'decline-terms',
  ACCEPT_TERMS: 'accept-terms'
}

export const UPDATE_CONTRACT_DETAILS = {
  VIEW_MODAL: 'view-update-contract-details-modal',
  START_SIGNING: 'start-signing-update-contract-details',
  COMPLETE_SIGNING: 'complete-signing-update-contract-details',
  TOGGLE_PROLONGATION_SWITCH: 'toggle-prolong-switch-update-contract-details',
  CONFIRM_PROLONGATION_SWITCH_CHANGE: 'confirm-prolong-switch-update-contract-details',
  CHANGE_IBAN: 'change-iban-update-contract-details',
  CONFIRM_CHANGE_IBAN: 'confirm-iban-update-contract-details'
}

export const SIGNING_VIEW = {
  VIEW_SIGNING: 'view-signing-view',
  VIEW_SIGNING_MODAL: 'view-signing-modal-signing-view',
  INIT_SIGNING: 'start-signing-signing-view',
  COMPLETE_SIGNING: 'complete-signing-signing-view'
}

export const SETTINGS_VIEW = {
  SAVE_PERSONAL_DETAILS: 'save-personal-details-settings-view',
  INIT_PASSWORD_CHANGE: 'init-password-change-settings-view',
  COMPLETE_PASSWORD_CHANGE: 'complete-password-change-settings-view',
  COMPLETE_PASSWORD_SET: 'complete-password-set-settings-view',
  DOWNLOAD_TAX_REPORT: 'download-tax-report'
}

export const APPLICATION_CONFIRMATION = {
  ON_PROLONG_CHANGE: 'toggle-prolong-switch-prolongation-flow-application-confirmation'
}

export const LOAN_DASHBOARD = {
  VIEW_APPLICATION_CARD: 'view-application-card-loan-dashboard',
  VIEW_LEVEL_ONE_OFFER_CARD: 'view-level-one-offer-card-loan-dashboard',
  VIEW_LEVEL_TWO_OFFER_CARD: 'view-level-two-offer-card-loan-dashboard',
  VIEW_LEVEL_THREE_OFFER_CARD: 'view-level-three-offer-card-loan-dashboard',
  VIEW_ACTIVE_ON_TRACK_CONTRACT_CARD: 'view-active-on-track-contract-card-loan-dashboard',
  VIEW_ACTIVE_IN_DEBT_CONTRACT_CARD: 'view-active-in-debt-contract-card-loan-dashboard',
  VIEW_CLOSED_CONTRACT_CARD: 'view-closed-contract-card-loan-dashboard',
  VIEW_PTP_CARD: 'view-ptp-card-loan-dashboard',
  VIEW_INVOICE_DETAILS: 'view-invoice-details-loan-dashboard',
  APPLY_FOR_LEVEL_ONE_OFFER: 'apply-for-level-one-offer-loan-dashboard',
  APPLY_FOR_LEVEL_TWO_OFFER: 'apply-for-level-two-offer-loan-dashboard',
  APPLY_FOR_LEVEL_THREE_OFFER: 'apply-for-level-three-offer-loan-dashboard'
}

export const CONTRACT_CHANGE_SIGNING = {
  COMPLETE: 'complete-loan-contract-change-signing',
  VIEW: 'view-loan-contract-change-sign-modal'
}

export const MANAGE_MY_LOAN = {
  SUBMIT: 'submit-manage-my-loan-application',
  VIEW: 'view-manage-my-loan-modal'
}

export const CAMPAIGNS = {
  VIEW_MODAL: 'view-campaign-modal',
  VIEW: 'view-campaigns',
  CLICK: 'click-campaign-modal',
  CLICK_REGULAR: 'click-regular-campaign',
  CLICK_UPSELL: 'click-upsell-campaign'
}

export const REVOLVING_DASHBOARD = {
  REVOLVING_EMPTY_VIEW: 'revolving-empty-view',
  REVOLVING_PROCEED_TO_APPLICATION: 'revolving-proceed-to-application',
  REVOLVING_VIEW_ACTIVE_ON_TRACK_CARD: 'revolving-view-active-on-track-card',
  REVOLVING_VIEW_ACTIVE_IN_DEBT_CARD: 'revolving-view-active-in-debt-card',
  REVOLVING_VIEW_CLOSED_CARD: 'revolving-view-closed-card',
  REVOLVING_CLICK_PAY_BACK: 'revolving-click-pay-back',
  REVOLVING_CLICK_INCREASE_LIMIT: 'revolving-click-increase-limit',
  REVOLVING_CLICK_TRANSFER_TO_IBAN: 'revolving-click-transfer-to-iban',
  REVOLVING_VIEW_PAY_BACK_MODAL: 'revolving-view-pay-back-modal',
  REVOLVING_VIEW_PAY_NOW_MODAL: 'revolving-view-pay-now-modal',
  REVOLVING_VIEW_INCREASE_LIMIT_MODAL: 'revolving-view-increase-limit-modal',
  REVOLVING_VIEW_TRANSFER_TO_IBAN_MODAL: 'revolving-view-transfer-to-iban-modal',
  REVOLVING_PROCEED_TO_CONFIRM_TRANSFER: 'revolving-proceed-to-confirm-transfer',
  REVOLVING_COMPLETE_TRANSFER_SUCCESSFUL: 'revolving-complete-transfer-successful',
  REVOLVING_COMPLETE_TRANSFER_FAILED: 'revolving-complete-transfer-failed',
  REVOLVING_CHOOSE_ANOTHER_IBAN_FOR_TRANSFER: 'revolving-choose-another-iban-for-transfer',
  REVOLVING_VIEW_CONTRACT_DETAILS: 'revolving-view-contract-details',
  REVOLVING_DOWNLOAD_CONTRACT_FILE: 'revolving-download-contract-file',
  REVOLVING_VIEW_INVOICES_HISTORY: 'revolving-view-invoices-history',
  REVOLVING_VIEW_INVOICE_MODAL: 'revolving-view-invoice-modal',
  REVOLVING_VIEW_MANAGE_MY_LOAN_MODAL: 'revolving-view-manage-my-loan-modal',
  REVOLVING_CHANGE_AUTOMATIC_PROLONGATION_TOGGLE: 'revolving-change-automatic-prolongation-toggle',
  REVOLVING_CONFIRM_AUTOMATIC_PROLONGATION_CHANGE: 'revolving-confirm-automatic-prolongation-change'
}

export const CREDIT_CARD_DASHBOARD = {
  EMPTY_VIEW: 'credit-card-empty-view',
  CLICK_PAY_BACK: 'credit-card-click-pay-back',
  VIEW_PAY_BACK_MODAL: 'credit-card-view-pay-back-modal'
}

export const DEMAND_DEPOSIT_DASHBOARD = {
  // views
  DEMAND_DEPOSIT_WITHDRAW: 'demand-view-withdraw',
  DEMAND_DEPOSIT_TOP_UP: 'demand-view-top-up',
  DEMAND_DEPOSIT_ACCOUNT_STATEMENT: 'demand-view-account-statement',
  DEMAND_DEPOSIT_VIEW_CONTRACT_DETAILS: 'demand-deposit-view-contract-details',
  DEMAND_EMPTY_VIEW: 'view-demand-deposit-empty-view',
  // actions
  DEMAND_CLICK_WITHDRAW: 'demand-click-withdraw',
  DEMAND_CLICK_TOP_UP: 'demand-click-top-up',
  DEMAND_CLICK_REOPEN_CONTRACT: 'demand-click-reopen-contract',
  DEMAND_WITHDRAW_CONFIRM: 'demand-withdraw-confirm',
  DEMAND_WITHDRAW_COMPLETE: 'demand-withdraw-complete',
  DEMAND_WITHDRAW_TO_ANOTHER_IBAN: 'demand-withdraw-to-another-iban'
}

export const TERM_DEPOSIT_DASHBOARD = {
  TERM_DEPOSIT_VIEW_CONTRACT_DETAILS: 'term-deposit-view-contract-details'
}

export function getViewFlowTrackerAction (flow, page) {
  return 'view-' + flow + '-' + page
}

export function getConfirmFlowTrackerAction (flow, page) {
  return 'confirm-' + flow + '-' + page
}

export function getViewDepositTrackerAction (state, type) {
  const subject = (type === DepositType.Demand) ? 'view-demand-deposit-' : 'view-deposit-'

  if (DEPO_STATES[state]) {
    return subject + state.toLowerCase().replace('_', '-')
  } else {
    return subject + 'unknown'
  }
}

export const INVOICE_MODAL = {
  VIEW: 'view-invoice-details-modal'
}
