<template>
  <div>
    <transaction-details-modal
      flavor="account"
      :onCloseRedirectUrl="backRoute"
      :isLoading="isLoading"
      :transaction="transaction"
      :isDownloadRunning="isDownloadButtonDisabled"
      :translations="translations"
      @download="onStatementDownloadRequested"
    />
  </div>
</template>
<script>
import '@bigbank/interface-components/dist/svg/illustration/credit-card'
import '@bigbank/interface-components/dist/svg/illustration/virtual-card'
import '@bigbank/interface-components/dist/svg/ui/chevron-down.svg'
import { isNil, isNull } from 'lodash'
import { mapState } from 'pinia'
import { AccountStatementFileType } from '@bigbank/dc-common/enums/credit-card.enums'
import TransactionDetailsModal from '@/components/transactions/TransactionDetailsModal.vue'
import { AccountRouteName } from '@/modules/account/const'
import api from '../../api'
import { useRootStore } from '../../../../store/root'
import { useAccountStatementStore } from '@account/store/accountStatementStore'

export default {
  name: 'my-transaction-details-modal',
  data () {
    return {
      isLoading: true,
      isDownloadButtonDisabled: false,
      transaction: null,
      transactionId: null,
      fileType: AccountStatementFileType.PDF
    }
  },
  components: {
    TransactionDetailsModal
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        this.setTransactionId(to.params.transactionId)
      }
    },
    transactionId: {
      immediate: true,
      handler: async function (newId, oldId) {
        if (!isNil(newId) && newId !== oldId) {
          this.setTransaction(this.getTransactionDetails())
          this.setLoading(false)
        }
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale']),
    ...mapState(useAccountStatementStore, ['accountStatement', 'accountsForStatement']),
    backRoute () {
      return AccountRouteName.MyTransactions
    },
    translations () {
      return {
        account: this.$pgettext('EDB', 'Account'),
        beneficiary: this.$pgettext('EDB', 'Beneficiary'),
        beneficiaryAccount: this.$pgettext('EDB', 'Beneficiary\'s account'),
        remitter: this.$pgettext('EDB', 'Remitter'),
        remitterAccount: this.$pgettext('EDB', 'Remitter\'s account'),
        amount: this.$pgettext('EDB', 'Amount'),
        paymentDateAndTime: this.$pgettext('EDB', 'Payment date and time'),
        paymentDescription: this.$pgettext('EDB', 'Payment description'),
        bankReferenceNo: this.$pgettext('EDB', 'Bank reference no'),
        status: this.$pgettext('EDB', 'Status'),
        transaction: this.$pgettext('EDB', 'Transaction'),
        reservation: this.$pgettext('EDB', 'Reservation')
      }
    }
  },
  methods: {
    setLoading (flag) { this.isLoading = flag },
    setTransaction (transaction) { this.transaction = transaction },
    setTransactionId (id) { this.transactionId = Number(id) },
    setDownloadingRunning (flag) { this.isDownloadButtonDisabled = flag },
    getTransactionDetails () {
      const transaction = this.accountStatement.transactions.find((transaction) => {
        return transaction.id.toString() === this.transactionId.toString()
      })

      if (isNull(transaction)) {
        console.warn(`Unable to find transaction | transactionId: ${this.transactionId}`)
        return
      }

      return transaction
    },
    async onStatementDownloadRequested (fileType) {
      try {
        const accountId = this.accountsForStatement.find(({ iban }) => iban === this.transaction.accountIban).id
        const searchRequest = {
          accountId,
          transactionId: this.transaction.id
        }
        this.setDownloadingRunning(true)
        await api.downloadAccountStatement(searchRequest, fileType)
      } catch (err) {
        console.error(err)
      }
      this.setDownloadingRunning(false)
    }
  }
}
</script>
