<template>
  <div>
    <bb-application-contract-card v-bind="cardProps" v-on="events" :class="spacerClass" />
    <component
      v-if="showInvoiceDetailsModal"
      :is="computedInvoiceDetailsModal"
      :contract="contract"
      @close="showInvoiceDetailsModal = !showInvoiceDetailsModal"
    />
    <requisites
      v-if="showRequisites"
      :contract="contract"
      @close="showRequisites = !showRequisites"
    />
    <sold-additional-details
      v-if="showSoldAdditionalDetails"
      :contract="contract"
      @close="showSoldAdditionalDetails = !showSoldAdditionalDetails"
    />
    <review-contract-signing-modal
      :visible="showReviewContractModal"
      :contract="contract"
      :contractId="contract.id"
      @close="showReviewContractModal = !showReviewContractModal"
    />
  </div>
</template>

<script>
import ConsumerLoanPayBack from '@/modules/loan/views/ConsumerLoanPayBack'
import RevolvingLoanPayback from '@/modules/loan/views/revolving/PayBack'
import invoiceDetailsTogglerMixin from '@/mixins/invoiceDetailsTogglerMixin'
import ReviewContractSigningModal from '@loan/components/signing/ReviewContractSigningModal'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { mapState } from 'pinia'
import statusTypes from './statusTypes'
import buttonTypes from './buttonTypes'
import Requisites from '../Requisites'
import SoldAdditionalDetails from '../SoldAdditionalDetails'
import { LOAN_DASHBOARD, REVOLVING_DASHBOARD } from '@/TrackingActions'
import { formatDate } from '@/plugins/dateFormatters'
import { useRootStore } from '../../../../store/root'

import '@bigbank/interface-components/dist/svg/ui/cash'
import '@bigbank/interface-components/dist/svg/ui/real-estate'
import '@bigbank/interface-components/dist/svg/ui/home'
import '@bigbank/interface-components/dist/svg/ui/car-side'
import '@bigbank/interface-components/dist/svg/ui/paint'
import '@bigbank/interface-components/dist/svg/ui/account-plus'
import '@bigbank/interface-components/dist/svg/ui/stethoscope'
import '@bigbank/interface-components/dist/svg/ui/leaf'
import '@bigbank/interface-components/dist/svg/ui/partner'
import '@bigbank/interface-components/dist/svg/ui/suitcase'
import { useLoanStore } from '@loan/store/loanStore'

const cardStatusMap = {
  [statusTypes.OK]: 'ok',
  [statusTypes.LATE]: 'late',
  [statusTypes.CLOSED]: 'closed',
  [statusTypes.PAID_OUT]: 'paidOut',
  [statusTypes.INACTIVE]: 'inactive',
  [statusTypes.TERMINATED_CLOSED]: 'terminated',
  [statusTypes.TERMINATED_SOLD]: 'terminated',
  [statusTypes.TERMINATED]: 'terminated'
}

const iconNamesByLoanTypeGroupCode = {
  SMALL_LOAN: 'ui-cash',
  REFINANCING_LOAN: 'ui-cash',
  HIRE_PURCHASE: 'ui-cash',
  PROJECT_LOAN: 'ui-account-plus',
  HOUSING: 'ui-home',
  CAR_LOAN: 'ui-car-side',
  RENOVATION_LOAN: 'ui-paint',
  MEDICAL_LOAN: 'ui-stethoscope',
  BUSINESS_LOAN: 'ui-cash',
  REAL_ESTATE: 'ui-real-estate',
  CREDIT_LINE: 'ui-cash',
  OPERATING_LOAN: 'ui-cash',
  PERSONAL_LOAN: 'ui-cash',
  RESTRUCTURING: 'ui-cash',
  PARTNER_LOAN: 'ui-partner',
  REGULAR_HIRE_PURCHASE: 'ui-cash',
  COMPENSATED_HIRE_PURCHASE: 'ui-cash',
  CAR_LOAN_BUSINESS: 'ui-cash',
  CAR_HIRE_PURCHASE: 'ui-car-side',
  CAR_HIRE_PURCHASE_COLLATERAL: 'ui-car-side',
  OPERATING_LEASE: 'ui-car-side',
  FINANCE_LEASE: 'ui-car-side',
  TRAVEL_LOAN: 'ui-suitcase',
  MOBILE_CREDIT_CARD: 'ui-cash',
  DEVELOPMENT_LOAN: 'ui-cash',
  PRIVATE_CREDIT: 'ui-cash',
  SMALL_LOAN_BUSINESS: 'ui-cash',
  INVESTMENT_LOAN: 'ui-cash',
  MORTGAGE_LOAN: 'ui-cash'
}

export default {
  name: 'contract-card',
  components: {
    ConsumerLoanPayBack,
    RevolvingLoanPayback,
    ReviewContractSigningModal,
    Requisites,
    SoldAdditionalDetails
  },
  mixins: [invoiceDetailsTogglerMixin],
  props: {
    currency: {
      type: String,
      required: true
    },
    contract: {
      type: Object,
      required: true
    },
    index: {
      required: false
    },
    count: {
      required: false
    },
    spacerClass: {
      required: false
    }
  },
  data () {
    return {
      showRequisites: false,
      showSoldAdditionalDetails: false,
      showInvoiceDetailsModal: false,
      showReviewContractModal: false
    }
  },
  mounted () {
    let action = ''
    switch (this.contract.typeCode) {
      case this.loanTypes.LOAN:
        action = LOAN_DASHBOARD.VIEW_ACTIVE_ON_TRACK_CONTRACT_CARD
        if (this.contract.isInDebt) {
          action = LOAN_DASHBOARD.VIEW_ACTIVE_IN_DEBT_CONTRACT_CARD
        } else if (this.contract.isClosed || this.contract.isTerminated) {
          action = LOAN_DASHBOARD.VIEW_CLOSED_CONTRACT_CARD
        }
        break
      case this.loanTypes.REVOLVING:
        action = REVOLVING_DASHBOARD.REVOLVING_VIEW_ACTIVE_ON_TRACK_CARD
        if (this.contract.isInDebt) {
          action = REVOLVING_DASHBOARD.REVOLVING_VIEW_ACTIVE_IN_DEBT_CARD
        } else if (this.contract.isClosed || this.contract.isTerminated) {
          action = REVOLVING_DASHBOARD.REVOLVING_VIEW_CLOSED_CARD
        }
        break
    }

    if (action && action.length > 0) {
      this.$tracker.action(action, {}, { contractId: this.contract.id.toString() })
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale']),
    ...mapState(useLoanStore, ['featureFlags', 'loanTypes']),
    cardProps () {
      const iconName = iconNamesByLoanTypeGroupCode[this.contract.loanTypeGroupCode] || 'ui-cash'

      return {
        type: 'loan',
        title: this.contract.contractNumber,
        secondaryTitle: this.translations.status[this.statusType],
        status: cardStatusMap[this.statusType],
        progress: this.progress,
        sections: this.cardSections,
        primaryButton: this.getPrimaryButton(),
        secondaryButton: this.getSecondaryButton(),
        description: this.description,
        icon: {
          name: iconName,
          fill: 'blue'
        }
      }
    },
    events () {
      return {
        primaryButtonClick: () => this.handleClick(this.cardPrimaryButton),
        secondaryButtonClick: () => this.handleClick(this.cardSecondaryButton)
      }
    },
    statusType () {
      if (this.contract.isTerminated) {
        if (this.contract.isSold) {
          return statusTypes.TERMINATED_SOLD
        } else if (this.contract.isClosed) {
          return statusTypes.TERMINATED_CLOSED
        } else {
          return statusTypes.TERMINATED
        }
      } else if (this.contract.isInDebt) {
        return statusTypes.LATE
      } else if (this.contract.isClosed) {
        return statusTypes.CLOSED
      } else {
        return statusTypes.OK
      }
    },
    progress () {
      if (this.contract.isTerminated) {
        return undefined
      }

      return {
        value: this.contract.madePayments,
        max: this.contract.numberOfPayments,
        unit: this.translations.contractCardUnits
      }
    },
    cardSections () {
      const sections = [{
        label: this.translations.fullDebtSum,
        content: formatMoneyWithCurrency(this.contract.totalDebtAmount, this.currency, this.locale),
        statuses: [statusTypes.LATE]
      }, {
        label: this.translations.dueDate,
        content: formatDate(this.contract.debtDueDate),
        statuses: [statusTypes.LATE]
      }, {
        label: this.translations.initialLoanAmount,
        content: formatMoneyWithCurrency(this.contract.initialPrincipalAmount, this.currency, this.locale),
        statuses: [
          statusTypes.CLOSED,
          statusTypes.TERMINATED_CLOSED,
          statusTypes.TERMINATED_SOLD
        ]
      }, {
        label: this.translations.paidOffDate,
        content: formatDate(this.contract.paidOffDate),
        statuses: [
          statusTypes.CLOSED,
          statusTypes.TERMINATED_CLOSED
        ]
      }, {
        label: this.translations.soldOffDate,
        content: formatDate(this.contract.soldOffDate),
        statuses: [
          statusTypes.TERMINATED_SOLD
        ]
      }, {
        label: this.translations.nextMonthlySum,
        content: formatMoneyWithCurrency(this.contract.nextPaymentAmount, this.currency, this.locale),
        statuses: [statusTypes.OK]
      }, {
        label: this.translations.nextDueDate,
        content: formatDate(this.contract.nextPaymentDate),
        statuses: [statusTypes.OK]
      }, {
        label: this.translations.remainingPrincipal,
        content: formatMoneyWithCurrency(this.contract.remainingPrincipal, this.currency, this.locale),
        statuses: [
          statusTypes.OK,
          statusTypes.LATE,
          statusTypes.CLOSED,
          statusTypes.PAID_OUT,
          statusTypes.INACTIVE,
          statusTypes.TERMINATED_SOLD,
          statusTypes.TERMINATED_CLOSED
        ]
      }]
        .filter(item => item.statuses.includes(this.statusType))
        .map(({ label, content }) => ({ label, content }))

      return sections.length ? sections : undefined
    },
    description () {
      const description = this.translations.description[this.statusType]

      if (!description) {
        return undefined
      }

      if (this.statusType === statusTypes.TERMINATED) {
        return description[this.contract.proceedingTypeCode] || description.DEFAULT
      }

      return description
    },
    cardPrimaryButton () {
      if (this.showSignButton) {
        return buttonTypes.SIGN
      }
      switch (this.statusType) {
        case statusTypes.TERMINATED:
          return buttonTypes.REQUISITES
        case statusTypes.LATE:
          return buttonTypes.PAY_NOW
        case statusTypes.CLOSED:
        case statusTypes.TERMINATED_CLOSED:
          return undefined
        case statusTypes.TERMINATED_SOLD:
          return buttonTypes.ADDITIONAL_DETAILS
        default:
          return buttonTypes.INVOICE_DETAILS
      }
    },
    cardSecondaryButton () {
      if (
        (
          this.featureFlags.enableContractDetails ||
          (
            this.featureFlags.enableContractDetailsForRevolvingLoan &&
            this.featureFlags.enableRevolvingLoan &&
            this.contract.typeCode === this.loanTypes.REVOLVING
          )
        ) && !this.contract.hideDetails
      ) {
        return buttonTypes.CONTRACT_DETAILS
      }

      return undefined
    },
    translations () {
      return {
        status: {
          [statusTypes.OK]: this.$gettext('on track'),
          [statusTypes.CLOSED]: this.$gettext('closed'),
          [statusTypes.LATE]: this.$gettext('behind schedule'),
          [statusTypes.TERMINATED]: this.$gettext('terminated'),
          [statusTypes.TERMINATED_SOLD]: this.$gettext('terminated'),
          [statusTypes.TERMINATED_CLOSED]: this.$gettext('terminated')
        },
        buttons: {
          [buttonTypes.SIGN]: this.$gettext('Review Contract'),
          [buttonTypes.CONTRACT_DETAILS]: this.$gettext('Contract Details'),
          [buttonTypes.INVOICE_DETAILS]: this.$gettext('Invoice Details'),
          [buttonTypes.PAY_NOW]: this.$gettext('PAY NOW'),
          [buttonTypes.REQUISITES]: this.$gettext('Requisites'),
          [buttonTypes.ADDITIONAL_DETAILS]: this.$gettext('Additional Details')
        },
        description: {
          [statusTypes.TERMINATED]: {
            BAILIFF: this.$pgettext('TERMINATED_BALIFF', 'Description for terminated contract card'),
            COL_AGENCY: this.$pgettext('TERMINATED_COLLECTION', 'Description for terminated contract card'),
            DEFAULT: this.$pgettext('TERMINATED_DEFAULT', 'Description for terminated contract card')
          }
        },
        fullDebtSum: this.$gettext('FULL DEBT SUM'),
        initialLoanAmount: this.$gettext('INITIAL LOAN AMOUNT'),
        nextMonthlySum: this.$gettext('NEXT MONTHLY SUM'),
        nextDueDate: this.$gettext('NEXT DUE DATE'),
        dueDate: this.$gettext('DUE DATE'),
        paidOffDate: this.$gettext('PAID OFF DATE'),
        remainingPrincipal: this.$gettext('REMAINING PRINCIPAL'),
        interestAmount: this.$gettext('INTEREST AMOUNT'),
        interestDueDate: this.$gettext('INTEREST DUE DATE'),
        soldOffDate: this.$gettext('SOLD OFF DATE'),
        contractCardUnits: this.$gettext('payments')
      }
    },
    showSignButton () {
      return this.contract.contractChangeSigning && !this.contract.contractChangeSigning.userSigned
    }
  },
  methods: {
    handleClick (buttonType) {
      switch (buttonType) {
        case buttonTypes.CONTRACT_DETAILS:
          this.$router.push({ name: 'loan-contract-details', params: { contractId: this.contract.id } })
          break
        case buttonTypes.INVOICE_DETAILS:
        case buttonTypes.PAY_NOW:
          this.showInvoiceDetailsModal = true
          break
        case buttonTypes.SIGN:
          this.showReviewContractModal = true
          break
        case buttonTypes.REQUISITES:
          this.showRequisites = true
          break
        case buttonTypes.ADDITIONAL_DETAILS:
          this.showSoldAdditionalDetails = true
          break
      }
    },
    getPrimaryButton () {
      return this.cardPrimaryButton
        ? {
            label: this.translations.buttons[this.cardPrimaryButton],
            inverted:
          (this.statusType === statusTypes.OK && this.cardPrimaryButton === buttonTypes.INVOICE_DETAILS) ||
          (this.cardPrimaryButton === buttonTypes.ADDITIONAL_DETAILS)
          }
        : undefined
    },
    getSecondaryButton () {
      return this.cardSecondaryButton
        ? {
            label: this.translations.buttons[this.cardSecondaryButton]
          }
        : undefined
    }
  }
}
</script>
