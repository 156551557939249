import { api } from '../api'
import flows from '../utils/flows'
import { mapState, mapActions, mapWritableState } from 'pinia'
import { getViewFlowTrackerAction, getConfirmFlowTrackerAction } from '@/TrackingActions'
import { ROUTER_PATHS } from '@/const'
import { DepositType, DepositRouteName } from '@deposits/const'
import { isNil } from 'lodash'
import { useRootStore } from '../../../store/root'
import { useDepositStore } from '@/modules/deposits/store/depositStore'

export default {
  data () {
    return {
      flows,
      flowData: {
        moneyOriginSettings: { isMoneyOriginAdditionalUploadRequired: false },
        moneyOrigins: []
      },
      activePage: '',
      progress: {
        max: 0,
        value: 0
      },
      defaults: {
        periodCode: 'MONTHS'
      },
      constants: {
        AGREEMENT_TYPE_CODES: {
          [DepositType.Term]: 'D',
          [DepositType.Demand]: 'DC'
        }
      },
      applicationCreationLoading: false,
      prevRoute: null
    }
  },
  inject: ['proceedingNotificationTranslation'],
  watch: {
    deposits (deposits) {
      if (!this.flowData.newDeposit?.id || !Array.isArray(deposits)) {
        return
      }
      const deposit = deposits.find(d => d.id === this.flowData.newDeposit.id)
      if (!isNil(deposit)) {
        this.flowData.newDeposit = Object.assign(this.flowData.newDeposit, deposit)
      }
    }
  },
  computed: {
    ...mapState(useRootStore, {
      openSavingDepositModal: 'openSavingDepositModal',
      globalFeatureFlags: 'featureFlags'
    }),
    ...mapState(useDepositStore, [
      'featureFlags',
      'demandDeposit',
      'deposits'
    ]),
    ...mapWritableState(useDepositStore, ['lastFlow', 'lastFlowData']),
    progressForTracker () {
      return this.progress.value + '/' + this.progress.max
    },
    isThisDemandDeposit () {
      return this.flowData.agreementTypeCode === this.constants.AGREEMENT_TYPE_CODES[DepositType.Demand]
    },
    isContractSigningDisabled () {
      return this.featureFlags.disableDepositSigning || this.isMoneyOriginAdditionalDocumentsViewRequired()
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    ...mapActions(useRootStore, ['checkTransactionsAllowed', 'showOpenSavingsDepositErrorModal', 'getDepositsCount']),
    ...mapActions(useDepositStore, [
      'fetchDeposits',
      'getPermittedIbanCountries',
      'load',
      'getDeposits'
    ]),
    async loadDifferentPlan (stepData) {
      this.flowData.accountDefaults = await api.getAccountDefaults(this.flowData.accountType.code, this.defaults.periodCode)
      Object.assign(this.flowData, stepData)
      this.setActivePage(flows.differentPlan)
    },
    async loadConfirmMoneyDetails () {
      const [moneyOriginLookupValues] = await Promise.all([
        api.getLookupValues('MONEY_ORIGIN'),
        this.getPermittedIbanCountries({ accountTypeCode: this.flowData.accountType.code })
      ])
      this.flowData.moneyOriginLookupValues = moneyOriginLookupValues
      this.setActivePage(flows.confirmMoneyDetails)
    },
    async loadApplicationConfirmation () {
      if (this.flowData.deposit) {
        this.flowData.paymentInfo = await api.getDepositPaymentInfo(this.flowData.deposit.id, this.flowData.amount)
        this.flowData.paymentInfo.displayDate = this.flowData.paymentInfo.transferAmount < 0
      } else {
        if (!this.flowData.paymentInfo) {
          this.flowData.paymentInfo = {}
        }
        this.flowData.paymentInfo.transferAmount = this.flowData.amount
        this.flowData.paymentInfo.displayDate = false
      }

      if (!this.flowData.expectedProfit && this.flowData.agreementTypeCode === this.constants.AGREEMENT_TYPE_CODES[DepositType.Term]) {
        this.flowData.expectedProfit = await api.getDepositInterest(this.flowData.amount, this.flowData.period, this.flowData.annualInterestRate, this.flowData.startDate, this.flowData.periodCode)
      }

      this.setActivePage(flows.applicationConfirmation)
    },
    loadContractConditions () {
      this.setActivePage(flows.contractConditions)
    },
    async submitDifferentPlan (stepData) {
      this.$tracker.action(getConfirmFlowTrackerAction(this.flowData.activeView, this.activePage), { progress: this.progressForTracker })
      Object.assign(this.flowData, stepData)
      this.loadConfirmMoneyDetails()
    },
    isMoneyOriginAdditionalDocumentsViewRequired () {
      return (
        !this.isProlongView() &&
        (
          this.flowData.moneyOriginSettings.isMoneyOriginAdditionalUploadRequired ||
          this.flowData.moneyOrigins.includes('OTHER')
        )
      )
    },
    isProlongView () {
      return this.flowData.activeView === this.flowData.views.prolongationFlow
    },
    async submitConfirmMoneyDetails (stepData) {
      this.$tracker.action(getConfirmFlowTrackerAction(this.flowData.activeView, this.activePage), { progress: this.progressForTracker })
      Object.assign(this.flowData, stepData)

      if (
        !this.globalFeatureFlags.enableMoneyOriginDocumentsUploadInSelfService &&
        this.featureFlags.enableMoneyOriginAdditionalDocumentsView &&
        !this.isProlongView()
      ) {
        const moneyOriginSettings = await api.getMoneyOriginSettings(this.flowData.amount)
        if (moneyOriginSettings) {
          this.flowData.moneyOriginSettings = moneyOriginSettings
        }

        if (this.isMoneyOriginAdditionalDocumentsViewRequired()) {
          this.setActivePage(flows.confirmMoneyDetailsAdditionalDocuments)
          return
        }
      }

      if (this.isThisDemandDeposit) {
        this.submitApplicationConfirmation(stepData)
      } else {
        await this.loadApplicationConfirmation()
      }
    },
    loadDepositsView (event) {
      if (event.eventSource === 'cross') {
        this.$router.push(this.getDepositProductRoute())
      }
    },
    async submitConfirmMoneyDetailsAdditionalDocuments (stepData) {
      this.$tracker.action(getConfirmFlowTrackerAction(this.flowData.activeView, this.activePage), { progress: this.progressForTracker })
      Object.assign(this.flowData, stepData)
      if (this.globalFeatureFlags.enableMoneyOriginDocumentsUploadInSelfService) {
        await this.endFlow(this.flowData.newDeposit)
      } else {
        if (this.isThisDemandDeposit) {
          this.submitApplicationConfirmation(stepData)
        } else {
          await this.loadApplicationConfirmation()
        }
      }
    },
    setActivePage (page) {
      this.setProgress(page)
      this.$tracker.action(getViewFlowTrackerAction(this.flowData.activeView, page), { progress: this.progressForTracker })
      this.activePage = page
    },
    setProgress (page) {
      if (this.stepMapping[page]) {
        this.progress.value = this.stepMapping[page]
      }
    },
    async submitApplicationConfirmation (stepData) {
      try {
        this.$tracker.action(getConfirmFlowTrackerAction(this.flowData.activeView, this.activePage), { progress: this.progressForTracker })
        this.applicationCreationLoading = true
        Object.assign(this.flowData, stepData)
        const { newDeposit } = await api.completeAppCreation(this.flowData, this.constants)
        this.flowData.newDeposit = newDeposit

        if (!this.isContractSigningDisabled) {
          this.pollUntilInSigningStateOrMoneyOriginFilesNeeded(newDeposit.id, 0)
          return
        }

        await this.endFlow(newDeposit)
      } catch (err) {
        await this.load()
        this.applicationCreationLoading = false
      }
    },
    async endFlow (deposit) {
      await this.load()
      await this.getDepositsCount()
      this.$router.push(this.getDepositProductRoute(deposit))
      this.$notify({ text: this.proceedingNotificationTranslation, duration: 10000 })
      this.applicationCreationLoading = false
    },
    closeModal () {
      const isDemandDepositView = this.$route.name === DepositRouteName.New && this.$route.params.depositType === DepositType.Demand
      const requireDemandDeposit = this.globalFeatureFlags.enableRequireSavingsDeposit
      const showSavingDepositModalConfirmation = isDemandDepositView && requireDemandDeposit && !this.demandDeposit

      if (showSavingDepositModalConfirmation) {
        this.showOpenSavingsDepositErrorModal(true)
        return
      }

      if (this.activePage === flows.contractConditions) {
        this.$router.push(this.getDepositProductRoute())
      } else if (this.prevRoute) {
        if (this.prevRoute.path === ROUTER_PATHS.CONFIRM_DATA || this.prevRoute.name === DepositRouteName.ConfirmDataLanding) {
          this.$router.push(this.getDepositProductRoute())
        } else {
          this.$router.push(this.prevRoute.path)
        }
      } else if (this.lastFlow === 'new-application') {
        this.$router.push(this.getDepositProductRoute())
      } else if (this.lastFlow === 'contract-details') {
        this.$router.push({ name: DepositRouteName.Contract, params: { contractId: this.lastFlowData.deposit.id } })
      } else {
        this.$router.push(this.getDepositProductRoute())
      }
    },
    async transactionsAllowed () {
      return this.checkTransactionsAllowed()
    },

    async startApplicationFlow (depositType) {
      const params = {}

      if (depositType) {
        params.depositType = depositType
      }

      this.lastFlow = 'new-application'
      this.$router.push({ name: DepositRouteName.New, params })
    },

    /**
     * Polls deposit state if it is in FORWARDED state until it is in WAITING_SIGNING state
     * or maximum rounds is reached or money origin files needed.
     * If WAITING_SIGNING state is reached, signing view is shown.
     * If deposit isWaitingMoneyOriginFiles, MO file upload is shown.
     * Otherwise will reload deposits and show dashboard.
     *
     * @param {*} id deposit id
     * @param {*} round nth polling round
     */
    pollUntilInSigningStateOrMoneyOriginFilesNeeded (id, round = 0) {
      const timeout = round > 0 ? 5000 : 2000
      setTimeout(async () => {
        const deposit = await api.getDepositDetails(id)
        this.flowData.newDeposit = Object.assign(this.flowData.newDeposit, deposit)
        if (deposit.state === 'WAITING_SIGNING') {
          await this.goToContractConditions()
        } else if (['FORWARDED', 'PROCEEDING'].includes(deposit.state) && round < 10) {
          if (this.globalFeatureFlags.enableMoneyOriginDocumentsUploadInSelfService) {
            if (deposit.isWaitingMoneyOriginFiles) {
              this.setActivePage(flows.confirmMoneyDetailsAdditionalDocuments)
              this.applicationCreationLoading = false

              return
            }
          }

          this.pollUntilInSigningStateOrMoneyOriginFilesNeeded(id, round + 1)
        } else {
          await this.endFlow(deposit)
        }
      }, timeout)
    },
    async goToContractConditions () {
      this.loadContractConditions()
      await this.getDeposits()
      await this.getDepositsCount()
      this.applicationCreationLoading = false
    },
    getDepositProductRoute (deposit = null) {
      const depositType = deposit ? deposit.depositType : this.mapAgreementTypeCodeToDepositType(this.flowData.agreementTypeCode)

      switch (depositType) {
        case DepositType.Demand:
          return { name: DepositRouteName.DemandDeposits }
        case DepositType.Term:
        default:
          return { name: DepositRouteName.TermDeposits }
      }
    },
    mapAgreementTypeCodeToDepositType (agreementTypeCode) {
      const agreementType = (agreementTypeCode || '').toUpperCase()

      switch (agreementType) {
        case this.constants.AGREEMENT_TYPE_CODES[DepositType.Demand]:
          return DepositType.Demand
        case this.constants.AGREEMENT_TYPE_CODES[DepositType.Term]:
        default:
          return DepositType.Term
      }
    }
  }
}
