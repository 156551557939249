import { defineStore } from 'pinia'
import depositStates from '../utils/depositStates.json'
import { DepositType, ActiveDepositSortedStatuses, ClosedDepositSortedStatuses } from '@deposits/const'
import { sortActiveDeposits, sortClosedDeposits } from '@deposits/utils/depositSorting'
import { request } from '@/plugins/utils'
import { api } from '../api'
import { useRootStore } from '../../../store/root'

import store from '@/store'
import { isEmpty } from 'lodash'

const requestPromises = {
  getFeatureFlags: null
}
export const useDepositStore = defineStore('deposit', {
  state: () => ({
    views: {
      depositView: 'deposits-view',
      signingView: 'signing-view',
      newApplicationFlow: 'new-application-flow',
      prolongationFlow: 'prolongation-flow',
      contractView: 'contract-view',
      contractDetails: 'contract-details'
    },
    deposits: {},
    currentDepositId: null,
    loading: false,
    depositStates,
    progress: {
      max: 0,
      value: 0
    },
    totalAccruedInterest: 0,
    accruedInterest: {
      [DepositType.Term]: undefined,
      [DepositType.Demand]: undefined
    },
    featureFlags: {},
    permittedIbans: [],
    appLoading: true,
    lastFlow: '',
    lastFlowData: {},
    pendingApplication: undefined,
    contractDetails: null
  }),
  getters: {
    currentDeposit (state) {
      return state.deposits[state.currentDepositId]
    },
    depositsGetter (state) {
      return Object.values(state.deposits)
    },
    depositsByProductAndCategory () {
      const intermediaries = this.depositsGetter.reduce((acc, deposit) => {
        acc[ClosedDepositSortedStatuses.includes(deposit.state) ? 'closed' : 'active'].push(deposit)
        return acc
      }, { active: [], closed: [] })

      return Object.values(DepositType).reduce((result, type) => {
        result[type] = {
          active: intermediaries.active.sort(sortActiveDeposits).filter(({ depositType }) => depositType === type),
          closed: intermediaries.closed.sort(sortClosedDeposits).filter(({ depositType }) => depositType === type)
        }
        return result
      }, {})
    },
    activeDepositsByProduct (state) {
      return Object.values(DepositType).reduce((result, type) => {
        result[type] = this.depositsGetter
          .filter(deposit => deposit.state?.toUpperCase() === state.depositStates.ACTIVE && deposit.depositType === type)
        return result
      }, {})
    },
    activeDepositCountByProduct () {
      return Object.entries(this.activeDepositsByProduct).reduce((result, [type, deposits]) => {
        result[type] = deposits.length
        return result
      }, {})
    },
    defaultDemandDeposit () {
      const allSortStates = ActiveDepositSortedStatuses.concat(ClosedDepositSortedStatuses)

      return this.depositsGetter
        .filter(({ depositType }) => depositType === DepositType.Demand)
        .sort((a, b) => allSortStates.indexOf(a.state?.toUpperCase()) > allSortStates.indexOf(b.state?.toUpperCase()) ? 1 : -1)
        .find(() => true)
    },
    demandDeposit () {
      return this.defaultDemandDeposit ?? null
    }
  },
  actions: {
    async load () {
      this.appLoading = true
      try {
        await Promise.all([
          this.getDeposits(),
          this.getFeatureFlags(),
          store.dispatch('campaign/getCampaigns', null, { root: true })
        ])
      } catch (e) {
      // Do nothing
      }
      this.appLoading = false
    },
    async getDeposits (showLoader = false) {
      if (showLoader) {
        this.appLoading = true
      }
      try {
        let deposits = await request('/deposit/api/deposits', {})
        if (!Array.isArray(deposits)) {
          deposits = [deposits]
        }
        this.setDeposits(deposits)
      } catch (e) {
      // Nothing should ever get here as `request` implements its own error handler
      }

      if (showLoader) {
        this.appLoading = false
      }
    },
    async fetchContractDetails ({ contractId, showLoader = true }) {
      if (showLoader) { this.appLoading = true }
      this.currentDepositId = contractId
      this.contractDetails = await api.getDepositDetails(contractId)
      this.appLoading = false
    },
    async fetchDeposits (skipLoading = false) {
      const wasLoadingWhenIGotHere = !!this.loading
      if (!skipLoading || !wasLoadingWhenIGotHere) {
        this.loading = true
      }
      await this.load()

      if (!skipLoading || !wasLoadingWhenIGotHere) {
        this.loading = false
      }
    },
    async initiateSigning (id) {
      const rootStore = useRootStore()
      this.loading = true
      if (isEmpty(this.deposits)) {
        await this.fetchDeposits(true)
      }

      this.currentDepositId = id

      const currentDeposit = this.deposits[id] ?? { isInProlongationState: false }

      const progress = currentDeposit.isInProlongationState ? 4 : 3
      this.progress = { max: progress, value: progress }

      rootStore.checkTransactionsAllowed()
      this.loading = false
    },
    async fetchAccruedInterest ({ productType }) {
      const { accruedInterest } = await api.getTotalAccruedInterest(productType)
      this.accruedInterest[productType] = accruedInterest
    },
    async fetchTotalAccruedInterest () {
      const { accruedInterest: demandAccruedInterest } = await api.getTotalAccruedInterest(DepositType.Demand)
      const { accruedInterest: termAccruedInterest } = await api.getTotalAccruedInterest(DepositType.Term)
      this.totalAccruedInterest = demandAccruedInterest + termAccruedInterest
    },
    async getFeatureFlags () {
      if (Object.keys(this.featureFlags).length) {
        return this.featureFlags
      }
      if (requestPromises.getFeatureFlags) {
        return requestPromises.getFeatureFlags
      }

      const featureFlagsRequest = request('/deposit/api/features')
      requestPromises.getFeatureFlags = featureFlagsRequest

      const featureFlags = await featureFlagsRequest
      this.featureFlags = featureFlags

      return featureFlags
    },
    getPendingApplications () {
      if (this.pendingApplication !== undefined) {
        return
      }

      return request('/deposit/api/deposit/application', {
        errHandlerOpts: {
          throwOnAllErrors: true
        }
      })
        .then(application => {
          this.pendingApplication = application
        })
        .catch(() => {
          this.pendingApplication = null
        })
    },
    getPermittedIbanCountries ({ accountTypeCode }) {
      if (this.permittedIbans && this.permittedIbans.length) {
        return Promise.resolve(this.permittedIbans)
      }

      const url = '/deposit/api/deposit/getPermittedIbanCountries?accountTypeCode=' + accountTypeCode

      return request(url, {})
        .then(ibanCountryCodes => {
          this.permittedIbans = ibanCountryCodes
          return this.permittedIbans
        })
    },
    setDeposits (deposits = []) {
      this.deposits = deposits.reduce((acc, deposit) => {
        acc[deposit.id] = deposit
        return acc
      }, {})
    }
  }
})
