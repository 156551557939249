<template>
  <div>
    <component
      v-if="isModalVisible"
      :is="modalComponent"
      v-bind="modalComponentProps"
      @onModalClosed="onModalClosed"
      @close="onModalClosed"
      @submit="onSignAndShowPinClicked"
    />
  </div>
</template>

<script>
import '@bigbank/interface-components/dist/svg/illustration/money'
import { CREDIT_CARD } from '@/const'
import CreditCardsSecretsModal from '@credit-card/components/modals/CreditCardSecretsModal'
import VirtualCardInfoModal from '@credit-card/components/modals/VirtualCardInfoModal'
import OrderVirtualCreditCardDebtModal from '@credit-card/components/modals/OrderVirtualCreditCardDebtModal'
import { CardType } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'
import { mapActions, mapState } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'
import Vue from 'vue'

export default {
  name: 'credit-card-order-modal',
  components: { CreditCardsSecretsModal, OrderVirtualCreditCardDebtModal },
  props: {
    visible: { required: true, type: Boolean }
  },
  data () {
    return {
      step: CREDIT_CARD.ORDER_MODAL_STEP.INFO,
      modalComponent: null,
      isModalVisible: false,
      cardId: null,
      isLoading: true
    }
  },
  computed: {
    ...mapState(useLoanStore, ['creditCardContract']),
    contract () {
      return this.creditCardContract
    },
    modalComponentProps () {
      return {
        type: CREDIT_CARD.SECRETS_MODAL_STEP.SHOWING_PAN_AND_CVV,
        visible: this.isModalVisible,
        cardType: CREDIT_CARD.TYPE.VIRTUAL,
        cardId: this.cardId,
        contractId: this.contract.id,
        signingWay: CREDIT_CARD.SECRETS_MODAL_SIGNING_WAY.THROUGH_ORDERING_VIRTUAL_CARD
      }
    }
  },
  methods: {
    ...mapActions(useLoanStore, [
      'getCreditCards',
      'orderCreditCard'
    ]),
    setModalComponent (component) { this.modalComponent = component },
    toggleModalVisibility (flag) {
      this.isModalVisible = flag
    },
    setLoading (flag) {
      this.isLoading = flag
      this.$emit('isLoading', flag)
    },
    setCardId (cardId) { this.cardId = cardId },
    setStep (step) { this.step = step },
    onSignAndShowPinClicked () {
      this.setModalComponent(CreditCardsSecretsModal)
      this.toggleModalVisibility(true)
      this.setStep(CREDIT_CARD.ORDER_MODAL_STEP.SIGN_CONTRACT_AND_SHOW_SECRETS)
    },
    async onModalClosed (refresh = true) {
      this.toggleModalVisibility(false)
      this.setModalComponent(null)
      if (refresh) {
        await this.getCreditCards(this.contract.id)
      }
      this.$emit('onModalClosed')
    }

  },
  async mounted () {
    this.setLoading(true)
    try {
      const cardId = await this.orderCreditCard(this.contract.id, CardType.VIRTUAL)

      if (cardId) {
        this.setCardId(cardId)
        this.setModalComponent(VirtualCardInfoModal)
        this.toggleModalVisibility(true)
      }
    } catch (error) {
      if (error?.data?.err_code === 'CUSTOMER_HAS_DEBT') {
        this.setModalComponent(OrderVirtualCreditCardDebtModal)
        this.toggleModalVisibility(true)
      } else {
        Vue.handleError(error)
      }
    } finally {
      this.setLoading(false)
    }
  }
}
</script>

<style lang="scss">

.credit-card-order-modal .illustration-money {
  width: 100%;
  max-width: 205px;
  height: 160px;
  margin: 33px auto 0;
  display: block;
}

.credit-card-order-modal .bb-spinner {
  margin: 35px auto;
}

.credit-card-order-modal .boxed-tooltip > span {
  color: $gray;
  text-decoration: underline;
}

.credit-card-order-modal .boxed-tooltip > span:hover {
  text-decoration: none;
  cursor: pointer;
}

</style>
